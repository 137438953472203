// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css-emotion/src/Css.bs.js");
var Curry = require("rescript/lib/js/curry.js");
var Icon__Jsx3 = require("../../uikit/reason/atoms/Icon/Icon__Jsx3.bs.js");
var MenuButton = require("../bindings/MenuButton.bs.js");
var View__Jsx3 = require("../../uikit/reason/helpers/View__Jsx3.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var FocusVisible = require("../../uikit/reason/helpers/FocusVisible.bs.js");
var Theme__Color = require("../common/Theme/Theme__Color.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var MenuButton$1 = require("@reach/menu-button");
var Theme__Color__Vars = require("../common/Theme/Theme__Color__Vars.bs.js");

((require('@reach/menu-button/styles.css')));

var buttonStyle = Curry._1(Css.style, {
      hd: Css.unsafe("color", "inherit"),
      tl: {
        hd: Css.unsafe("font", "inherit"),
        tl: {
          hd: Css.textAlign(Css.inherit_),
          tl: {
            hd: Css.unsafe("WebkitFontSmoothing", "inherit"),
            tl: {
              hd: Css.unsafe("WebkitAppearance", "none"),
              tl: {
                hd: Css.important(Css.borderStyle("none")),
                tl: {
                  hd: Css.marginLeft({
                        NAME: "px",
                        VAL: 5
                      }),
                  tl: {
                    hd: Css.marginRight({
                          NAME: "px",
                          VAL: 15
                        }),
                    tl: {
                      hd: Css.padding2("zero", {
                            NAME: "px",
                            VAL: 15
                          }),
                      tl: {
                        hd: Css.lineHeight("normal"),
                        tl: {
                          hd: Css.background("transparent"),
                          tl: {
                            hd: Css.display("block"),
                            tl: {
                              hd: Css.cursor("pointer"),
                              tl: {
                                hd: Css.position("relative"),
                                tl: {
                                  hd: Css.height({
                                        NAME: "percent",
                                        VAL: 100
                                      }),
                                  tl: {
                                    hd: Css.selector("&[aria-expanded='true']", {
                                          hd: Css.before({
                                                hd: Css.contentRule({
                                                      NAME: "text",
                                                      VAL: "\" \""
                                                    }),
                                                tl: {
                                                  hd: Css.display("block"),
                                                  tl: {
                                                    hd: Css.backgroundColor("transparent"),
                                                    tl: {
                                                      hd: Css.height({
                                                            NAME: "px",
                                                            VAL: 0
                                                          }),
                                                      tl: {
                                                        hd: Css.width({
                                                              NAME: "px",
                                                              VAL: 0
                                                            }),
                                                        tl: {
                                                          hd: Css.position("absolute"),
                                                          tl: {
                                                            hd: Css.bottom({
                                                                  NAME: "px",
                                                                  VAL: 9
                                                                }),
                                                            tl: {
                                                              hd: Css.left({
                                                                    NAME: "px",
                                                                    VAL: 17
                                                                  }),
                                                              tl: {
                                                                hd: Css.borderLeft({
                                                                      NAME: "px",
                                                                      VAL: 6
                                                                    }, "solid", "transparent"),
                                                                tl: {
                                                                  hd: Css.borderRight({
                                                                        NAME: "px",
                                                                        VAL: 6
                                                                      }, "solid", "transparent"),
                                                                  tl: {
                                                                    hd: Css.borderBottom({
                                                                          NAME: "px",
                                                                          VAL: 6
                                                                        }, "solid", Theme__Color.gallery),
                                                                    tl: /* [] */0
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }
                                              }),
                                          tl: {
                                            hd: Css.after({
                                                  hd: Css.contentRule({
                                                        NAME: "text",
                                                        VAL: "\" \""
                                                      }),
                                                  tl: {
                                                    hd: Css.display("block"),
                                                    tl: {
                                                      hd: Css.backgroundColor("transparent"),
                                                      tl: {
                                                        hd: Css.height({
                                                              NAME: "px",
                                                              VAL: 0
                                                            }),
                                                        tl: {
                                                          hd: Css.width({
                                                                NAME: "px",
                                                                VAL: 0
                                                              }),
                                                          tl: {
                                                            hd: Css.position("absolute"),
                                                            tl: {
                                                              hd: Css.bottom({
                                                                    NAME: "px",
                                                                    VAL: 7
                                                                  }),
                                                              tl: {
                                                                hd: Css.zIndex(1),
                                                                tl: {
                                                                  hd: Css.left({
                                                                        NAME: "px",
                                                                        VAL: 17
                                                                      }),
                                                                  tl: {
                                                                    hd: Css.borderLeft({
                                                                          NAME: "px",
                                                                          VAL: 6
                                                                        }, "solid", "transparent"),
                                                                    tl: {
                                                                      hd: Css.borderRight({
                                                                            NAME: "px",
                                                                            VAL: 6
                                                                          }, "solid", "transparent"),
                                                                      tl: {
                                                                        hd: Css.borderBottom({
                                                                              NAME: "px",
                                                                              VAL: 6
                                                                            }, "solid", {
                                                                              NAME: "rgb",
                                                                              VAL: [
                                                                                255,
                                                                                255,
                                                                                255
                                                                              ]
                                                                            }),
                                                                        tl: /* [] */0
                                                                      }
                                                                    }
                                                                  }
                                                                }
                                                              }
                                                            }
                                                          }
                                                        }
                                                      }
                                                    }
                                                  }
                                                }),
                                            tl: /* [] */0
                                          }
                                        }),
                                    tl: FocusVisible.focusStyle
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    });

var listStyle = Curry._1(Css.style, {
      hd: Css.marginTop({
            NAME: "px",
            VAL: -10
          }),
      tl: {
        hd: Css.zIndex(200),
        tl: {
          hd: Css.position("relative"),
          tl: /* [] */0
        }
      }
    });

function HeaderSearchDropdown$ProfileMenu(props) {
  var match = FocusVisible.useFocusVisible(undefined);
  return JsxRuntime.jsxs(MenuButton$1.Menu, {
              children: [
                JsxRuntime.jsx(MenuButton.Button.make, {
                      className: Curry._1(Css.merge, {
                            hd: buttonStyle,
                            tl: {
                              hd: match.className,
                              tl: /* [] */0
                            }
                          }),
                      onFocus: match.onFocus,
                      onBlur: match.onBlur,
                      children: JsxRuntime.jsx(View__Jsx3.make, {
                            alignItems: "center",
                            justifyContent: "flexStart",
                            flexDirection: "row",
                            children: Caml_option.some(JsxRuntime.jsx(Icon__Jsx3.make, {
                                      icon: "Search",
                                      size: 22,
                                      color: Theme__Color__Vars.gray
                                    }))
                          })
                    }),
                JsxRuntime.jsxs(MenuButton.List.make, {
                      className: listStyle,
                      children: [
                        JsxRuntime.jsx(MenuButton.Link.make, {
                              children: "Search properties",
                              to_: "/properties?as_m=web&as_c=header_search_dropdown"
                            }),
                        JsxRuntime.jsx(MenuButton.Link.make, {
                              children: "Search buyer needs",
                              to_: "/client_needs?as_m=web&as_c=header_search_dropdown"
                            }),
                        JsxRuntime.jsx(MenuButton.Link.make, {
                              children: "Search broadcast messages",
                              to_: "/messages?as_m=web&as_c=header_search_dropdown"
                            })
                      ]
                    })
              ]
            });
}

var ProfileMenu = {
  buttonStyle: buttonStyle,
  listStyle: listStyle,
  make: HeaderSearchDropdown$ProfileMenu
};

var $$default = HeaderSearchDropdown$ProfileMenu;

exports.ProfileMenu = ProfileMenu;
exports.default = $$default;
exports.__esModule = true;
/*  Not a pure module */
