// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var React = require("react");
var Buttons__Jsx3 = require("../../uikit/reason/atoms/Buttons/Buttons__Jsx3.bs.js");
var RescriptRelay = require("rescript-relay/src/RescriptRelay.bs.js");
var SystemMessages = require("../systemMessages/SystemMessages.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var RescriptRelay_Query = require("rescript-relay/src/RescriptRelay_Query.bs.js");
var UseNewVersionNoticeQuery_graphql = require("../../__generated__/UseNewVersionNoticeQuery_graphql.bs.js");

var convertVariables = UseNewVersionNoticeQuery_graphql.Internal.convertVariables;

var convertResponse = UseNewVersionNoticeQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = UseNewVersionNoticeQuery_graphql.Internal.convertWrapRawResponse;

function use(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.useQuery(convertVariables, UseNewVersionNoticeQuery_graphql.node, convertResponse, param, param$1, param$2, param$3, param$4);
}

function useLoader(param) {
  return RescriptRelay_Query.useLoader(convertVariables, UseNewVersionNoticeQuery_graphql.node, (function (prim) {
                return prim;
              }), param);
}

function usePreloaded(param) {
  return RescriptRelay_Query.usePreloaded(UseNewVersionNoticeQuery_graphql.node, convertResponse, (function (prim) {
                return prim;
              }), param);
}

function $$fetch(param, param$1, param$2, param$3, param$4, param$5) {
  return RescriptRelay_Query.$$fetch(UseNewVersionNoticeQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4, param$5);
}

function fetchPromised(param, param$1, param$2, param$3, param$4) {
  return RescriptRelay_Query.fetchPromised(UseNewVersionNoticeQuery_graphql.node, convertResponse, convertVariables, param, param$1, param$2, param$3, param$4);
}

function retain(param, param$1) {
  return RescriptRelay_Query.retain(UseNewVersionNoticeQuery_graphql.node, convertVariables, param, param$1);
}

function commitLocalPayload(param, param$1, param$2) {
  return RescriptRelay_Query.commitLocalPayload(UseNewVersionNoticeQuery_graphql.node, convertVariables, convertWrapRawResponse, param, param$1, param$2);
}

var Query = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain,
  commitLocalPayload: commitLocalPayload
};

function useNewVersionNotice(param) {
  var match = use(undefined, "store-only", undefined, undefined, undefined);
  var hasNotifiedAboutNewFrontendCodeVersion = match.hasNotifiedAboutNewFrontendCodeVersion;
  var codeVersionOfFrontendBundle = match.codeVersionOfFrontendBundle;
  var environment = RescriptRelay.useEnvironmentFromContext();
  var match$1 = SystemMessages.useSystemMessages();
  var addMessage = match$1.addMessage;
  React.useEffect((function (param) {
          var match = process.env.NEXT_PUBLIC_BUILD_ID;
          if (match !== undefined && codeVersionOfFrontendBundle === undefined) {
            commitLocalPayload(environment, undefined, {
                  codeVersionOfFrontendBundle: match,
                  hasNotifiedAboutNewFrontendCodeVersion: false
                });
          }
          
        }), [codeVersionOfFrontendBundle]);
  React.useEffect((function (param) {
          if (hasNotifiedAboutNewFrontendCodeVersion !== undefined && hasNotifiedAboutNewFrontendCodeVersion) {
            return ;
          }
          if (codeVersionOfFrontendBundle === undefined) {
            return ;
          }
          var intervalId = setInterval((function (param) {
                  var run = async function (param) {
                    var res = await fetch("/api/build-id");
                    var dict = await res.json();
                    if (!Array.isArray(dict) && (dict === null || typeof dict !== "object") && typeof dict !== "number" && typeof dict !== "string" && typeof dict !== "boolean") {
                      return ;
                    }
                    if (!(typeof dict === "object" && !Array.isArray(dict))) {
                      return ;
                    }
                    var match = dict["buildId"];
                    if (match !== undefined && !(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean" || !(typeof match === "string" && match !== codeVersionOfFrontendBundle))) {
                      commitLocalPayload(environment, undefined, {
                            codeVersionOfFrontendBundle: codeVersionOfFrontendBundle,
                            hasNotifiedAboutNewFrontendCodeVersion: true
                          });
                      return Curry._1(addMessage, SystemMessages.Message.make("updated-code-notice", "info", "A new version of TAN is available. Please refresh this page to upgrade", 0, (function (param) {
                                        return JsxRuntime.jsx(Buttons__Jsx3.OutlineButton.make, {
                                                    onClick: (function (param) {
                                                        window.location.reload();
                                                      }),
                                                    children: "Refresh"
                                                  });
                                      }), false, undefined));
                    }
                    
                  };
                  run();
                }), 300000);
          return (function (param) {
                    clearInterval(intervalId);
                  });
        }), [
        codeVersionOfFrontendBundle,
        hasNotifiedAboutNewFrontendCodeVersion
      ]);
}

exports.Query = Query;
exports.useNewVersionNotice = useNewVersionNotice;
/* react Not a pure module */
